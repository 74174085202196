import { Str } from "core";
import {
    Button,
    ButtonColor,
    CommonIcon,
    Confirmation,
    Counter,
    DialogSize,
    H3,
    Icon,
    IconButton,
    Link,
    NavigationBar,
    NonModal,
    Paragraph,
    Span,
    Tooltip,
    useBrandedMemo,
} from "design-system";
import { LlmFeedback } from "Everlaw/LLM/LlmFeedback";
import {
    ANSWER_CITATION_REGEX_PATTERN,
    TextWithCitations,
} from "Everlaw/Oracle/OracleCitationComponents";
import { AllRankedReferences, ReferencesUsedInAnswer } from "Everlaw/Oracle/OracleReferenceTables";
import {
    copyOracleGenerationToClipboard,
    deleteInfo,
    getAllRankedFactoidsForAnswer,
    getCitationsForAnswer,
    getNumPotentiallyRelevantDocs,
    getSearchResultURL,
    OracleInfo,
    OracleQueryStep,
} from "Everlaw/Oracle/OracleUtils";
import * as Project from "Everlaw/Project";
import * as DateUtil from "Everlaw/DateUtil";
import { ToastBoxLocation } from "Everlaw/ToastBoxManager";
import { IconSize } from "Everlaw/UI/Feedback";
import * as Perm from "Everlaw/PermissionStrings";
import * as User from "Everlaw/User";
import * as React from "react";
import { ReactNode, useEffect, useState, useRef, useId, useCallback, memo } from "react";

const GENERATING_TOOLTIP_TEXT = "Response is being generated";

interface OracleCardExpandButtonProps {
    open: boolean;
    toggleOpen: (open: boolean) => void;
    isGeneratingAnswer: boolean;
}

function OracleCardExpandButton({
    open,
    toggleOpen,
    isGeneratingAnswer,
}: OracleCardExpandButtonProps) {
    const generatingButtonIconRef = useRef<SVGSVGElement>(null);
    const generatingButtonTooltipId = useId();
    return (
        <>
            <IconButton
                aria-label={open ? "Close answer" : "Open answer"}
                onClick={() => toggleOpen(!open)}
                disabled={isGeneratingAnswer}
                aria-describedby={isGeneratingAnswer ? generatingButtonTooltipId : undefined}
            >
                {open ? (
                    <Icon.ChevronDown aria-hidden={true} ref={generatingButtonIconRef} />
                ) : (
                    <Icon.ChevronRight aria-hidden={true} ref={generatingButtonIconRef} />
                )}
            </IconButton>
            {isGeneratingAnswer && (
                <Tooltip id={generatingButtonTooltipId} target={generatingButtonIconRef}>
                    {GENERATING_TOOLTIP_TEXT}
                </Tooltip>
            )}
        </>
    );
}

interface OracleCardProps {
    info: OracleInfo;
    open: boolean;
    toggleOpen: (open: boolean) => void;
}

export function OracleCard({ info, open, toggleOpen }: OracleCardProps): ReactNode {
    const didInit = useRef(false);
    // Open the card on submission when the card is first created
    useEffect(() => {
        if (!didInit.current) {
            didInit.current = true;
            if (info.currentStep !== OracleQueryStep.ANSWER_CONSOLIDATION) {
                toggleOpen(true);
            }
        }
    }, [info.currentStep, toggleOpen]);

    const generatingRowRef = useRef<HTMLElement>(null);
    const generatingRowTooltipId = useId();

    const isGeneratingAnswer = !info.consolidatedAnswer;
    const hasError =
        info.questionSubmission.hasQueryError || info.questionSubmission.hasQueueingError;

    return (
        <>
            <article
                className={hasError ? "oracle-card oracle-error-card-border" : "oracle-card"}
                aria-describedby={isGeneratingAnswer ? generatingRowTooltipId : undefined}
                ref={generatingRowRef}
            >
                {info.questionSubmission && (
                    <header className={"flex-horizontal"}>
                        <div className={"oracle-info-header-main"}>
                            <H3.Small>{info.questionSubmission.questionText}</H3.Small>
                            <HeaderInfo info={info} />
                        </div>
                        <div className={"oracle-card-button-wrapper"}>
                            <OracleCardExpandButton
                                open={open}
                                toggleOpen={toggleOpen}
                                isGeneratingAnswer={isGeneratingAnswer}
                            />
                        </div>
                    </header>
                )}
                {open
                    && (hasError ? (
                        <OracleErrorState
                            info={info}
                            isQueueingError={info.questionSubmission.hasQueueingError}
                        />
                    ) : info.currentStep === OracleQueryStep.ANSWER_CONSOLIDATION ? (
                        <OracleAnswer info={info} />
                    ) : (
                        <OracleGeneratingState info={info} />
                    ))}
            </article>
            {isGeneratingAnswer && !hasError && (
                <Tooltip id={generatingRowTooltipId} target={generatingRowRef}>
                    {GENERATING_TOOLTIP_TEXT}
                </Tooltip>
            )}
        </>
    );
}

interface PossiblyRelevantContentProps {
    info: OracleInfo;
    openSearchResult: (info: OracleInfo) => Promise<void>;
}

const PossiblyRelevantContent = memo(({ info, openSearchResult }: PossiblyRelevantContentProps) => {
    return (
        <div className={"oracle-potentially-relevant-search-result"}>
            <CommonIcon.Success size={20} />
            <Paragraph>
                {Str.countOf(info.searchResult?.numRelevantDocs ?? 0, "document")
                    + " were identified as potentially relevant. "}
            </Paragraph>
            <Link
                onClick={() => {
                    openSearchResult(info);
                }}
            >
                View in results table
            </Link>
        </div>
    );
});

interface OracleNonModalReferencesProps {
    info: OracleInfo;
    citationToDisplayMap: Record<number, number>;
}

enum NavigationTab {
    POSSIBLY_RELEVANT_DOCUMENTS = "POSSIBLY_RELEVANT_DOCUMENTS",
    ALL_GENERATED_FACTS = "ALL_GENERATED_FACTS",
    FACTS_USED_IN_RESPONSE = "FACTS_USED_IN_RESPONSE",
}

function OracleReferences({
    info,
    citationToDisplayMap,
}: OracleNonModalReferencesProps): ReactNode {
    const [selectedNavigationTab, setSelectedNavigationTab] = useState<NavigationTab>(
        NavigationTab.ALL_GENERATED_FACTS,
    );

    // There is room for optimizing data fetching
    useEffect(() => {
        if (info.searchResult === undefined) {
            getNumPotentiallyRelevantDocs(info.id as number);
        }
    }, [info]);

    // There is room for optimizing data fetching
    useEffect(() => {
        if (info.allFactoids === undefined) {
            getAllRankedFactoidsForAnswer(info.id as number, info.consolidatedAnswer.answerId);
        }
    }, [info]);

    // There is room for optimizing data fetching
    useEffect(() => {
        // Although this is also done in OracleAnswer, having it also here allows this component
        // to be reused outside OracleAnswer
        if (info.answerCitations === undefined) {
            getCitationsForAnswer(info.id as number, info.consolidatedAnswer.answerId);
        }
    }, [info]);

    const openSearchResult = useCallback(async (info: OracleInfo) => {
        if (info.searchResultUrl === undefined) {
            await getSearchResultURL(info.id as number);
        }
        window.open(info.searchResultUrl);
    }, []);

    const navigationTabs = useBrandedMemo(() => {
        return [
            <NavigationBar.Tab
                key={NavigationTab.POSSIBLY_RELEVANT_DOCUMENTS}
                id={NavigationTab.POSSIBLY_RELEVANT_DOCUMENTS}
                label={"Possibly relevant documents"}
                additionalContent={<Counter>{info.searchResult?.numRelevantDocs ?? 0}</Counter>}
            />,
            <NavigationBar.Tab
                key={NavigationTab.ALL_GENERATED_FACTS}
                id={NavigationTab.ALL_GENERATED_FACTS}
                label={"All generated facts"}
                additionalContent={<Counter>{info.allFactoids?.length ?? 0}</Counter>}
            />,
            <NavigationBar.Tab
                key={NavigationTab.FACTS_USED_IN_RESPONSE}
                id={NavigationTab.FACTS_USED_IN_RESPONSE}
                label={"Facts used in response"}
                additionalContent={<Counter>{info.answerCitations?.length ?? 0}</Counter>}
            />,
        ];
    }, [info.searchResult, info.allFactoids, info.answerCitations]);

    const TabContent = memo(
        ({
            selectedNavigationTab,
            info,
            openSearchResult,
        }: {
            selectedNavigationTab: NavigationTab;
            info: OracleInfo;
            openSearchResult: (info: OracleInfo) => Promise<void>;
        }) => {
            switch (selectedNavigationTab) {
                case NavigationTab.POSSIBLY_RELEVANT_DOCUMENTS:
                    return (
                        <PossiblyRelevantContent info={info} openSearchResult={openSearchResult} />
                    );
                case NavigationTab.ALL_GENERATED_FACTS:
                    return (
                        <AllRankedReferences
                            info={info}
                            citationToDisplayMap={citationToDisplayMap}
                        />
                    );
                case NavigationTab.FACTS_USED_IN_RESPONSE:
                    return (
                        <ReferencesUsedInAnswer
                            info={info}
                            citationToDisplayMap={citationToDisplayMap}
                        />
                    );
            }
        },
    );

    return (
        <>
            <NavigationBar<NavigationTab>
                selected={selectedNavigationTab}
                setSelected={setSelectedNavigationTab}
            >
                {navigationTabs}
            </NavigationBar>
            <TabContent
                selectedNavigationTab={selectedNavigationTab}
                info={info}
                openSearchResult={openSearchResult}
            />
        </>
    );
}

interface OracleAnswerImageCardProps {
    stepNum: number;
    imageSrc: string;
    captionText: string;
    altText: string;
}

function OracleAnswerImageCard({
    stepNum,
    imageSrc,
    captionText,
    altText,
}: OracleAnswerImageCardProps): ReactNode {
    return (
        <div className={"oracle-answer-creation-image-card"}>
            <H3.Tiny>Step {stepNum}</H3.Tiny>
            <img src={imageSrc} className={"margin-bottom-8"} alt={altText} />
            <Paragraph.Small className={"oracle-answer-creation-image-card__description"}>
                {captionText}
            </Paragraph.Small>
        </div>
    );
}

function OracleAnswerCreationIcon(): ReactNode {
    const resourcesIconRef = useRef<SVGSVGElement>(null);
    const resourcesIconTooltipId = useId();
    const [showAnswerInfoNonModal, setShowAnswerInfoNonModal] = useState(false);

    return (
        <>
            <IconButton onClick={() => setShowAnswerInfoNonModal(true)}>
                <Icon.InfoCircle
                    ref={resourcesIconRef}
                    size={IconSize.SMALL}
                    aria-describedby={resourcesIconTooltipId}
                />
            </IconButton>
            <NonModal
                title={"Creating an answer"}
                size={DialogSize.MEDIUM}
                visible={showAnswerInfoNonModal}
                onHide={() => setShowAnswerInfoNonModal(false)}
            >
                {/*TODO - fix alignment/centering of cards*/}
                <div className={"flex-horizontal gap-16"}>
                    <OracleAnswerImageCard
                        stepNum={1}
                        imageSrc={"/images/oracle/answer-creation-1.svg"}
                        captionText={
                            "Based on your question, Everlaw identifies a set of a few hundred "
                            + "documents that could contain relevant information"
                        }
                        altText={
                            "Boxes are displayed in 2 rows, representing documents that are "
                            + "identified as potentially relevant"
                        }
                    />
                    <OracleAnswerImageCard
                        stepNum={2}
                        imageSrc={"/images/oracle/answer-creation-2.svg"}
                        captionText={
                            "Facts related to your question are generated from the top documents "
                            + "and ranked on a 1-5 scale for relevance"
                        }
                        altText={
                            "Lines within the boxes represent the facts that are generated for a "
                            + "subset of potentially relevant documents"
                        }
                    />
                    <OracleAnswerImageCard
                        stepNum={3}
                        imageSrc={"/images/oracle/answer-creation-3.svg"}
                        captionText={
                            "Facts with the highest relevance score are used to generate the "
                            + "response"
                        }
                        altText={
                            "Numbers are added to the boxes, representing the relevance scores "
                            + "given to documents with generated facts"
                        }
                    />
                </div>
            </NonModal>
            <Tooltip id={resourcesIconTooltipId} target={resourcesIconRef}>
                View how answers are created
            </Tooltip>
        </>
    );
}

interface OracleAnswerProps {
    info: OracleInfo;
}

function OracleAnswer({ info }: OracleAnswerProps): ReactNode {
    const citationsLoaded = info.answerCitations !== undefined;
    const [showReferences, setShowReferences] = useState(false);
    const [citationToDisplayMap, setCitationToDisplayMap] = useState<Record<number, number>>({});

    const copyIconRef = useRef<SVGSVGElement>(null);
    const copyIconTooltipId = useId();

    useEffect(() => {
        if (!citationsLoaded) {
            getCitationsForAnswer(info.id as number, info.consolidatedAnswer.answerId);
        }
    }, [info, citationsLoaded]);

    useEffect(() => {
        getNumPotentiallyRelevantDocs(info.id as number);
    }, [info]);

    const text = info.consolidatedAnswer.answerText;

    // Use useEffect to build the citation ID to display ID mapping
    useEffect(() => {
        if (!text) {
            return;
        }

        const map: Record<number, number> = {};
        let nextDisplayId = 1;
        let match;

        // Create a new regex instance for the mapping pass
        const regexForMapping = new RegExp(ANSWER_CITATION_REGEX_PATTERN);

        // Build the citation ID to display ID mapping
        while ((match = regexForMapping.exec(text)) !== null) {
            const citationIds = match[1].split(",").map((num) => parseInt(num.trim(), 10));

            // Add each citation ID to the map if it doesn't exist yet
            citationIds.forEach((citationId) => {
                if (map[citationId] === undefined) {
                    map[citationId] = nextDisplayId++;
                }
            });
        }

        setCitationToDisplayMap(map);
    }, [text]); // Only recalculate when text changes

    const userHasDeletePerms = User.me.can(
        Perm.GENERATE_ORACLE,
        Project.CURRENT,
        User.Override.ELEVATED_OR_ORGADMIN,
    );

    return (
        info.consolidatedAnswer && (
            <div className={"oracle-flex-vertical gap-16"}>
                {info.consolidatedAnswer.answerText && (
                    <div>
                        <TextWithCitations
                            info={info}
                            citationToDisplayMap={citationToDisplayMap}
                        />
                    </div>
                )}
                {citationsLoaded && (
                    <>
                        <div className={"oracle-flex-vertical gap-8"}>
                            <div className={"flex-centered gap-4"}>
                                <button
                                    className={"oracle-ref-table-button flex-centered"}
                                    onClick={() => setShowReferences(!showReferences)}
                                    aria-label={
                                        showReferences ? "Hide references" : "Close references"
                                    }
                                >
                                    {showReferences ? (
                                        <Icon.ChevronDown size={20} aria-hidden={true} />
                                    ) : (
                                        <Icon.ChevronRight size={20} aria-hidden={true} />
                                    )}
                                    <Span.Semibold>Resources</Span.Semibold>
                                </button>
                                <OracleAnswerCreationIcon />
                            </div>
                            {showReferences && (
                                <OracleReferences
                                    info={info}
                                    citationToDisplayMap={citationToDisplayMap}
                                />
                            )}
                        </div>
                    </>
                )}
                <div className={"flex-horizontal"}>
                    <LlmFeedback
                        completionIds={[info.consolidatedAnswer.completionId]}
                        toastBoxLocation={ToastBoxLocation.DEFAULT}
                    />
                    <div className={"flex gap-8"}>
                        <IconButton
                            onClick={() => copyOracleGenerationToClipboard(info)}
                            aria-describedby={copyIconTooltipId}
                        >
                            <Icon.Clipboard ref={copyIconRef} />
                        </IconButton>
                        <Tooltip id={copyIconTooltipId} target={copyIconRef}>
                            Copy to clipboard
                        </Tooltip>
                        {userHasDeletePerms && <OracleDeletionIcon info={info} />}
                    </div>
                </div>
            </div>
        )
    );
}

interface HeaderInfoProps {
    info: OracleInfo;
}

function HeaderInfo({ info }: HeaderInfoProps): ReactNode {
    const { timestamp, searchResultId, name } = info.questionSubmission;
    const timestampAsDate = DateUtil.asDate(timestamp);

    return (
        <Paragraph.Small className={"oracle-info-header-text"}>
            {DateUtil.displayDateLocal(timestampAsDate)},{" "}
            {DateUtil.displayTimeLocal(timestampAsDate)} •{" "}
            {searchResultId ? (
                <>
                    Searched across{" "}
                    <Link
                        href={Project.current().urlFor("search", { id: searchResultId })}
                        newTab={true}
                    >
                        <Span.Small>specific document set</Span.Small>
                    </Link>
                </>
            ) : (
                "Searched across entire document set"
            )}{" "}
            • {name}
        </Paragraph.Small>
    );
}

interface OracleGeneratingStateProps {
    info: OracleInfo;
}

function OracleGeneratingState({ info }: OracleGeneratingStateProps): ReactNode {
    const msg: string = info.inQueue ? "Queued for response" : "Generating response";
    let subMsg;
    if (info.inQueue) {
        subMsg = undefined;
    } else if (info.currentStep === OracleQueryStep.QUESTION_SUBMISSION) {
        subMsg = "Identifying possibly relevant documents";
    } else if (info.currentStep === OracleQueryStep.QUESTION_SEARCH && info.searchResult) {
        subMsg = `Identified ${info.searchResult.numRelevantDocs} possibly relevant documents and preparing for fact extraction`;
    } else if (
        info.currentStep === OracleQueryStep.EXCERPTS_EXTRACTION
        && info.excerptsExtraction
    ) {
        subMsg = `Analyzing ${info.excerptsExtraction.numDocsEvaluated} documents for relevance`;
    } else if (info.currentStep === OracleQueryStep.FACTOIDS_RESPONSE && info.factoidsResponse) {
        subMsg = `Generating response from ${info.factoidsResponse.factIds.length} most relevant facts`;
    }
    return (
        <div className={"flex gap-8"}>
            <CommonIcon.AutoGenerating size={20} />
            <div className={"oracle-flex-vertical gap-4"}>
                {msg && <div className={"oracle-generating-response-text"}>{msg}</div>}
                {subMsg && (
                    <div className={"bb-text--color-secondary bb-text--small"}>{subMsg}</div>
                )}
            </div>
        </div>
    );
}

interface OracleErrorStateProps {
    info: OracleInfo;
    isQueueingError: boolean;
}

function OracleErrorState({ info, isQueueingError }: OracleErrorStateProps): ReactNode {
    return (
        <div className={"oracle-flex-vertical gap-8"}>
            <div className={"flex gap-8"}>
                <CommonIcon.ErrorTriangle size={IconSize.SMALL} />
                <div>
                    <Paragraph className={"bb-text--color-danger"}>
                        {isQueueingError
                            ? "Everlaw AI is currently experiencing a high volume of questions "
                              + "in the queue. Please try again in a few minutes."
                            : "There was an error generating a response to this question"}
                    </Paragraph>
                </div>
            </div>
            {info.userId === User.me.id && (
                <div className={"oracle-error-footer"}>
                    <OracleDeletionIcon info={info} />
                </div>
            )}
        </div>
    );
}

interface OracleDeletionIconProps {
    info: OracleInfo;
}

function OracleDeletionIcon({ info }: OracleDeletionIconProps): ReactNode {
    const deleteIconRef = useRef<SVGSVGElement>(null);
    const deleteIconTooltipId = useId();

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    return (
        <>
            <IconButton
                onClick={() => setShowDeleteDialog(true)}
                aria-describedby={deleteIconTooltipId}
            >
                <Icon.Trash ref={deleteIconRef} />
            </IconButton>
            <Tooltip id={deleteIconTooltipId} target={deleteIconRef}>
                Delete response
            </Tooltip>
            <Confirmation
                // TODO: Oracle naming
                title={"Delete Project Query generation"}
                size={DialogSize.SMALL}
                onHide={() => setShowDeleteDialog(false)}
                onCancel={() => setShowDeleteDialog(false)}
                onComplete={() => {
                    deleteInfo(info);
                    setShowDeleteDialog(false);
                }}
                visible={showDeleteDialog}
                primaryButton={<Button color={ButtonColor.DANGER}>Delete</Button>}
            >
                {/*TODO: Oracle naming*/}
                <Paragraph>
                    Are you sure you want to delete this Project Query generation?
                </Paragraph>
            </Confirmation>
        </>
    );
}
