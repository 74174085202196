import { Str } from "core";
import {
    EverColor,
    Icon,
    IconButton,
    Popover,
    Tooltip,
    Paragraph,
    TooltipPlacement,
    Link,
} from "design-system";
import * as Base from "Everlaw/Base";
import { useStore } from "Everlaw/Base";
import {
    getFactoids,
    openDocInNewWindow,
    OracleCitedFactoid,
    OracleFactoidObj,
    OracleInfo,
} from "Everlaw/Oracle/OracleUtils";
import * as React from "react";
import { ReactNode, useCallback, useMemo, useRef, useState } from "react";

const defaultTooltipPlacements = [
    TooltipPlacement.BOTTOM_START,
    TooltipPlacement.BOTTOM_END,
    TooltipPlacement.TOP_START,
    TooltipPlacement.TOP_END,
];

interface CitationContentProps {
    submissionId: number;
    citedFactoid: OracleCitedFactoid;
    factObj: OracleFactoidObj;
    needOuterDiv: boolean;
    citationToDisplayMap: Record<number, number>;
}

function CitationContent({
    submissionId,
    citedFactoid,
    factObj,
    needOuterDiv,
    citationToDisplayMap,
}: CitationContentProps): ReactNode {
    const innerContent = (
        <div className={"flex-vertical gap-16"}>
            <Paragraph.SmallBold className={"gray-text"}>
                {`${citationToDisplayMap[citedFactoid.referenceId]} • ${citedFactoid.batesDisplay}`}
            </Paragraph.SmallBold>
            <Paragraph>{factObj?.quote}</Paragraph>
            <Link onClick={() => openDocInNewWindow(submissionId, citedFactoid.docId)}>
                View document
            </Link>
        </div>
    );
    return needOuterDiv ? (
        <div className={"oracle-citation-content"}>{innerContent}</div>
    ) : (
        innerContent
    );
}

interface CitationProps {
    citedFactoid: OracleCitedFactoid;
    submissionId: number;
    citationToDisplayMap: Record<number, number>;
}

/**
 * Custom hook to manage the action of fetching and displaying factoids.
 *
 * @param {OracleCitedFactoid[]} citedFactoids - An array of cited factoids that need to be checked
 * and potentially fetched.
 * @param {number} submissionId - The submission ID associated with the factoids.
 * @returns {Object} An object containing:
 * - `show`: A boolean state indicating whether the factoids should be displayed.
 * - `setShow`: A function to update the `show` state.
 * - `handleAction`: A callback function to handle the action of fetching factoids if needed and
 * updating the display state.
 * - `factRefs`: An array of factoid references from the global store.
 */
function useFactoidAction(citedFactoids: OracleCitedFactoid[], submissionId: number) {
    const [show, setShow] = useState(false);
    const factRefs = useStore(Base.globalStore(OracleFactoidObj));

    const cachedFactIds = useMemo(
        () => new Set(factRefs.map((ref) => ref.obj.id as number)),
        [factRefs],
    );
    const factIdsToFetch = useMemo(
        () => citedFactoids.map((f) => f.factId).filter((f) => !cachedFactIds.has(f)),
        [citedFactoids, cachedFactIds],
    );

    const handleAction = useCallback(() => {
        if (factIdsToFetch.length > 0) {
            getFactoids(factIdsToFetch, submissionId).then(() => {
                setShow(true);
            });
        } else {
            setShow(true);
        }
    }, [factIdsToFetch, submissionId]);

    return { show, setShow, handleAction, factRefs };
}

/**
 * Citation component for single citations for when there are less than
 * {@link CITATION_NUMBER_CUTOFF} citations The component opens the main Popover containing answer
 * reference information on **hover**
 */
export function Citation({
    citedFactoid,
    submissionId,
    citationToDisplayMap,
}: CitationProps): ReactNode {
    const ref = useRef(null);
    const { handleAction } = useFactoidAction([citedFactoid], submissionId);

    const factObj = Base.get(OracleFactoidObj, citedFactoid.factId);

    return (
        <>
            <div
                className={"oracle-ref-citation-wrapper"}
                tabIndex={0}
                aria-label={`Open citation number ${citedFactoid.factId}`}
                ref={ref}
            >
                <Link onClick={() => openDocInNewWindow(submissionId, citedFactoid.docId)}>
                    <cite className={"oracle-ref-citation"}>
                        {citationToDisplayMap[citedFactoid.referenceId]}
                    </cite>
                </Link>
            </div>
            <Tooltip
                onShow={handleAction}
                className={"oracle-citation-tooltip"}
                target={ref}
                renderOutsideParent={false}
                placement={defaultTooltipPlacements}
            >
                <CitationContent
                    submissionId={submissionId}
                    citedFactoid={citedFactoid}
                    factObj={factObj}
                    needOuterDiv={false}
                    citationToDisplayMap={citationToDisplayMap}
                />
            </Tooltip>
        </>
    );
}

const CITATION_NUMBER_CUTOFF = 5;

interface MultipleCitationsProps {
    citedFactoids: OracleCitedFactoid[];
    submissionId: number;
    citationToDisplayMap: Record<number, number>;
}

/**
 * Citation component to group citations for when there are more than {@link
 * CITATION_NUMBER_CUTOFF} citations. The component opens the main Popover containing answer
 * reference information on **click** and opens a tooltip indicating the number of citations on
 * **hover**.
 */
export function MultipleCitations({
    citedFactoids,
    submissionId,
    citationToDisplayMap,
}: MultipleCitationsProps): ReactNode {
    const ref = useRef(null);
    const { show, setShow, handleAction } = useFactoidAction(citedFactoids, submissionId);
    const refDisplayMsg = Str.countOf(citedFactoids.length, "more reference");
    const ariaLabelMsg = `Open ${Str.countOf(citedFactoids.length, "more citation")}`;

    return (
        <>
            <div ref={ref}>
                <IconButton onClick={() => handleAction()} aria-label={ariaLabelMsg}>
                    <Icon.Dots aria-hidden={true} size={12} color={EverColor.EVERBLUE_50} />
                </IconButton>
                <Popover
                    show={show}
                    setShow={setShow}
                    target={ref}
                    placement={defaultTooltipPlacements}
                >
                    <div className={"oracle-multiple-citations-popover-contents"}>
                        {citedFactoids.map((f, index, arr) => {
                            const factObj = Base.get(OracleFactoidObj, f.factId);
                            return (
                                <React.Fragment key={f.factId}>
                                    <CitationContent
                                        submissionId={submissionId}
                                        citedFactoid={f}
                                        factObj={factObj}
                                        needOuterDiv={true}
                                        citationToDisplayMap={citationToDisplayMap}
                                    />
                                    {index < arr.length - 1 && (
                                        <hr className={"margin-top-12 margin-bottom-12"} />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </Popover>
            </div>
            <Tooltip
                aria-label={refDisplayMsg}
                target={ref}
                renderOutsideParent={true}
                placement={defaultTooltipPlacements}
            >
                {refDisplayMsg}
            </Tooltip>
        </>
    );
}

export const ANSWER_CITATION_REGEX_PATTERN = /\[(\d+(?:,\s*\d+)*)\]/g;

interface TextWithCitationsProps extends TextWithoutCitationsProps {
    citationToDisplayMap: Record<number, number>;
}

/**
 * A React component that renders text with inline citations.
 *
 * This component parses a text string containing citation references in the format [n] or [n,m,o],
 * where n, m, and o are numeric citation identifiers. It splits the text at these references and
 * replaces them with interactive citation components while preserving the original text structure.
 *
 * @component
 * @example
 * const info = {
 *   consolidatedAnswer: {
 *     answerText: "This is a sample text [1] with multiple citations [2,3]."
 *   }
 * };
 */
export function TextWithCitations({
    info,
    citationToDisplayMap,
}: TextWithCitationsProps): ReactNode {
    const text = info.consolidatedAnswer.answerText;
    const parts: ReactNode[] = [];
    const regex = new RegExp(ANSWER_CITATION_REGEX_PATTERN);
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {
        // Add text before the citation
        if (match.index > lastIndex) {
            parts.push(text.slice(lastIndex, match.index));
        }

        // Add the citation component
        const citations = match[1].split(",").map((num) => parseInt(num.trim(), 10));
        parts.push(
            <CitationGroup
                info={info}
                key={match.index}
                refIds={citations}
                citationToDisplayMap={citationToDisplayMap}
            />,
        );

        lastIndex = regex.lastIndex;
    }

    // Add any remaining text after the last citation
    if (lastIndex < text?.length) {
        parts.push(text.slice(lastIndex));
    }

    return <>{parts}</>;
}

interface TextWithoutCitationsProps {
    info: OracleInfo;
}

export function textWithoutCitations({ info }: TextWithoutCitationsProps): string {
    const text = info.consolidatedAnswer.answerText;
    const parts: string[] = [];
    const regex = new RegExp(ANSWER_CITATION_REGEX_PATTERN);
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {
        // Add text before the citation, stripping trailing whitespace
        if (match.index > lastIndex) {
            parts.push(text.slice(lastIndex, match.index).trimEnd());
        }

        // Unlike TextWithCitations, skip citation text
        lastIndex = regex.lastIndex;
    }

    // Add any remaining text after the last citation
    if (lastIndex < text?.length) {
        parts.push(text.slice(lastIndex));
    }

    return parts.join("");
}

interface CitationGroupProps {
    info: OracleInfo;
    citationToDisplayMap: Record<number, number>;
    refIds: number[];
}

function CitationGroup({ info, refIds, citationToDisplayMap }: CitationGroupProps): ReactNode {
    const factIdToFactoidMap = useMemo(
        () => new Map((info?.answerCitations ?? []).map((f) => [f.referenceId, f])),
        [info?.answerCitations],
    );

    return (
        <div className={"oracle-citation-group"}>
            {refIds
                .slice(0, CITATION_NUMBER_CUTOFF)
                .map((id) => factIdToFactoidMap.get(id))
                .filter((f) => f !== undefined)
                .map((f) => (
                    <Citation
                        key={f.factId}
                        citedFactoid={f}
                        submissionId={info.questionSubmission.submissionId}
                        citationToDisplayMap={citationToDisplayMap}
                    />
                ))}
            {refIds.length > CITATION_NUMBER_CUTOFF && (
                <MultipleCitations
                    citedFactoids={refIds
                        .slice(CITATION_NUMBER_CUTOFF)
                        .map((id) => factIdToFactoidMap.get(id))
                        .filter((f) => f !== undefined)}
                    submissionId={info.questionSubmission.submissionId}
                    citationToDisplayMap={citationToDisplayMap}
                />
            )}
        </div>
    );
}
